
import { Component, Vue } from 'vue-property-decorator';
import animatedScrollTo from 'animated-scroll-to';
import { VsPage } from '@/components/pages/vs-page';


@Component({ })
class OnboardingAulas extends Vue {
    private actualPage: number = 0;
    private canNavigate: boolean = true;

    private navigate(index: number){
        if(this.actualPage + index >= 0 && this.actualPage + index <= 2 && this.canNavigate) {
            this.canNavigate = false;
            this.actualPage += index;
            animatedScrollTo([window.innerWidth * this.actualPage, 0], {
                elementToScroll: (this.$refs.onboarding as VsPage).$el,
                minDuration: 600,
                cancelOnUserAction: false,
                maxDuration: 600
            });
            setTimeout(() => {
                this.canNavigate = true;
            }, 600);
        }
        
    }
}

export default OnboardingAulas;
